<!-- eslint-disable vue/no-v-html -->
<template>
	<div v-if="(earlyLaunch || (noAutoshipCountries.includes(country)) && !$user.details().override_no_autoship_flag) || (autoshipProductsLoading ? false : !autoshipProductsHasData)">
		<b-alert
			show
			variant="info">
			<div class="text-center">
				<h4>{{ translate('early_launch_message') }}</h4>
				<h5 class="text-muted">
					{{ translate('early_launch_submessage') }}
				</h5>
				<b-button
					variant="primary"
					class="mt-3"
					@click="$router.push({ name: 'Home' })">
					{{ translate('go_to_dashboard') }}
				</b-button>
			</div>
		</b-alert>
	</div>
	<div v-else>
		<div v-if="!isActive">
			<div class="row">
				<div class="col-12 text-center">
					<b-alert
						show
						variant="warning">
						<p v-html="translate('inactive_account_warning')" />
						<b-button
							variant="primary"
							@click="$router.push({ name: 'Store'});">
							{{ translate('inactive_account_button') }}
						</b-button>
					</b-alert>
				</div>
			</div>
		</div>
		<div v-else>
			<is-loading
				:loading="autoshipInformationLoading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				size="bg" />

			<!-- Shipping address and card information -->
			<template v-if="!autoshipInformationLoading && autoshipInformationHasData">
				<b-row>
					<b-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  mt-4 mb-2">
						<b-card
							class="h-100 border-top-dark-blue">
							<b-row>
								<b-col>
									<template v-if="hasShippingAddress">
										<b-row>
											<b-col>
												<b><p class="mb-0 color-primary-darken">{{ translate('shipping_address') }}</p></b>
												<p class="mb-0">
													<b>{{ autoshipInformationData.attributes.shipping_address.first_name }} {{ autoshipInformationData.attributes.shipping_address.last_name }}</b>
												</p><p class="mb-0 p-0 text-left">
													{{ autoshipInformationData.attributes.shipping_address.address }}
													{{ autoshipInformationData.attributes.shipping_address.address2 }}
												</p>
												<p>
													{{ autoshipInformationData.attributes.shipping_address.city }}
													<template
														v-if="autoshipInformationData.attributes.shipping_address.region !== null"
														class="mb-0 p-0 text-left">
														{{ autoshipInformationData.attributes.shipping_address.region.name }}, {{ autoshipInformationData.attributes.shipping_address.postcode }}
													</template>
													<template
														v-else
														class="mb-0 p-0 text-left">
														{{ autoshipInformationData.attributes.shipping_address.postcode }}
													</template>
													{{ translate(autoshipInformationData.attributes.shipping_address.country.code.toLowerCase()) }}
													{{ autoshipInformationData.attributes.shipping_address.telephone }}
												</p>
											</b-col>
											<b-col>
												<b-button
													:to="{ name: 'AddressBook' }"
													class="float-right"
													variant="link">
													{{ translate('edit') }}
												</b-button>
											</b-col>
										</b-row>
									</template>
									<template v-else>
										<b-row>
											<b-col>
												<b><p class="mb-0 pt-2 color-primary-darken">{{ translate('shipping_address') }}</p></b>
											</b-col>
											<b-col class="col-auto">
												<b-button
													:to="{ name: 'AddressBook' }"
													class="float-right"
													variant="link">
													{{ translate('edit') }}
												</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col class="mt-2">
												<b-alert
													show
													variant="danger">
													{{ translate('missing_shipping_address') }}
												</b-alert>
											</b-col>
										</b-row>
									</template>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  mt-4 mb-2">
						<b-card
							class="h-100 border-top-dark-blue">
							<b-row class="mb-2">
								<b-col>
									<template v-if="hasCreditCard">
										<b-row>
											<b-col>
												<b><p class="mb-0 color-primary-darken">{{ translate('credit_card') }}</p></b>
												<p class="mb-0">
													<b>{{ autoshipInformationData.attributes.credit_card.cardholder }}</b>
												</p>
												<p v-if="autoshipInformationData.attributes.credit_card.last_digits">
													<img
														:src="require(`@/assets/images/common/cc/card/${cardLogo}.png`)"
														align="left"
														class="text-right mr-2">
													{{ translate('card_ending') }}
													<b>{{ autoshipInformationData.attributes.credit_card.last_digits.length > 0 ? autoshipInformationData.attributes.credit_card.last_digits : '' }}</b>
												</p>
											</b-col>
											<b-col class="col-auto">
												<b-button
													:to="{ name: 'AllCreditCards' }"
													class="float-right"
													variant="link">
													{{ translate('edit') }}
												</b-button>
											</b-col>
											<b-col cols="12">
												<b><p class="mb-0 color-primary-darken">{{ translate('billing_address') }}</p></b>
												<p class="mb-0">
													<b>{{ autoshipInformationData.attributes.credit_card.address.first_name }} {{ autoshipInformationData.attributes.credit_card.address.last_name }}</b>
												</p><p class="mb-0 p-0 text-left">
													{{ autoshipInformationData.attributes.credit_card.address.address }}
													{{ autoshipInformationData.attributes.credit_card.address.address2 }}
												</p>
												<p class="mb-0">
													{{ autoshipInformationData.attributes.credit_card.address.city }}
													<template
														v-if="autoshipInformationData.attributes.credit_card.address.region !== null"
														class="mb-0 p-0 text-left">
														{{ autoshipInformationData.attributes.credit_card.address.region.name }}, {{ autoshipInformationData.attributes.credit_card.address.postcode }}
													</template>
													<template
														v-else
														class="mb-0 p-0 text-left">
														{{ autoshipInformationData.attributes.credit_card.address.postcode }}
													</template>
													{{ translate(autoshipInformationData.attributes.credit_card.address.country.code.toLowerCase()) }}
													{{ autoshipInformationData.attributes.credit_card.address.telephone }}
												</p>
											</b-col>
										</b-row>
										<b-row
											v-if="backupCreditCards > 0">
											<b-col
												cols="12"
												class="p-text text-left mt-2">
												{{ translate('backups_selected', {backup_cards: backupCreditCards}) }}
											</b-col>
										</b-row>
									</template>
									<template v-else>
										<b-row>
											<b-col>
												<b><p class="mb-0 pt-2 color-primary-darken">{{ translate('credit_card') }}</p></b>
											</b-col>
											<b-col>
												<b-button
													:to="{ name: 'AllCreditCards' }"
													class="float-right"
													variant="link">
													{{ translate('edit') }}
												</b-button>
											</b-col>
										</b-row>
										<b-row>
											<b-col class="mt-2">
												<b-alert
													show
													variant="danger">
													{{ translate('missing_card_billing_address') }}
												</b-alert>
											</b-col>
										</b-row>
									</template>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
			</template>

			<!-- Autoship configurations (activate button, products, configurations) -->
			<template v-if="!autoshipInformationLoading && autoshipInformationHasData">
				<b-card
					class="mt-4 h-100 border-top-dark-blue">
					<b-row>
						<b-col>
							<switch-toggle
								v-model="enableAutoshipChecked"
								variant="success"
								pill
								@change="handleAutoshipToggle" />
							<b>
								<h5
									style="display:inline"
									class="ml-2">{{ translate('enable_autoship') }}</h5>
							</b>
							<p class="ml-2 p-text">
								{{ translate(`enable_autoship_text_${enableCurrentProducts}`) }}
							</p>
							<hr>
						</b-col>
					</b-row>
					<b-collapse
						id="products_collapse"
						v-model="enableCurrentProducts">
						<!-- General information (if is activated, autoship date, countdown) -->
						<b-row>
							<b-col>
								<b-card class="pb-2">
									<template
										v-if="!loadingAutoshipConfiguration">
										<div class="row">
											<b-col class="col-lg-8 col-md-8 col-sm-12 d-flex flex-column justify-content-between">
												<b>
													<h5
														style=" display:inline"
														class="ml-2">{{ translate('autoship_configuration') }}</h5>
												</b>
												<div>
													<!-- <b-row v-if="showSignature.includes( $user.auth() ? $user.details().country.iso_code_2 : null )">
														<b-col>
															<switch-toggle
																:checked="signatureChecked"
																:disabled="!enableCurrentProducts"
																variant="success"
																pill
																class="ml-3"
																@change="signatureAutoship(true)" />
															<b><p
																style="display:inline"
																class="ml-2 mb-0">{{ translate('signature_required') }}</p></b>
															<p class="ml-2 p-text">
																{{ translate(`signature_required_text_${signatureChecked}`) }}
															</p>
														</b-col>
													</b-row> -->
													<b-row>
														<b-col>
															<b-alert
																show
																class="my-2"
																:variant="(hasAutoshipDate && !isPastDate && hasShippingAddress && hasCreditCard && !isTodayDate) ? 'info' : 'danger'">
																<b-row>
																	<b-col
																		v-if="!hasCreditCard || !hasShippingAddress || isPastDate || isTodayDate"
																		sm="12">
																		<ul class="pl-3 mb-1">
																			<li v-if="!hasShippingAddress">
																				{{ translate('missing_shipping_address') }}
																			</li>
																			<li v-if="!hasCreditCard">
																				{{ translate('missing_card_billing_address') }}
																			</li>
																			<li v-if="isTodayDate">
																				{{ translate('autoship_process_daily') }}
																			</li>
																			<li v-else-if="isPastDate && isNewGen">
																				{{ translate('autoship_past_date_config') }}
																			</li>
																			<li v-else-if="isPastDate">
																				{{ translate('autoship_past_date') }}
																			</li>
																		</ul>
																	</b-col>
																</b-row>
																<div
																	v-if="!showNewGenData && !showDatepicker"
																	class="grid-container">
																	<div>
																		<b><h6 class="d-inline mr-1 font-weight-bold">{{ `${translate('next_autoship_date')}:` }}</h6></b>
																		<span class="d-inline text-left">{{ autoshipDateFormat }}</span>
																	</div>
																	<div
																		v-if="customerRoles.includes($user.details().type)">
																		<b-button
																			class="btn btn-sm bg-primary-alt"
																			:disabled="setAutoshipDateLoading"
																			variant="secondary"
																			@click="toggleDatePicker">
																			<template v-if="!setAutoshipDateLoading">
																				{{ translate('change_autoship_date') }}
																			</template>
																			<template v-else>
																				<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
																			</template>
																		</b-button>
																	</div>
																	<template v-if="isNewGen && !showNewGenData">
																		<div>
																			<b><h6 class="d-inline mr-1 font-weight-bold">{{ `${translate('autoship_periodicity')}:` }}</h6></b>
																			<span class="d-inline text-left">{{ userAutoshipPeriodicity }}</span>
																		</div>
																		<div>
																			<b-button
																				class="btn btn-sm bg-primary-alt"
																				:disabled="setAutoshipDateLoading"
																				variant="secondary"
																				@click="toggleNewGenData">
																				<template v-if="!setAutoshipDateLoading">
																					{{ translate('change_autoship_configuration') }}
																				</template>
																				<template v-else>
																					<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
																				</template>
																			</b-button>
																		</div>
																	</template>
																</div>
																<b-row>
																	<b-col
																		v-if="isNewGen && !showNewGenData"
																		sm="12"
																		class="mt-2 px-3">
																		<span
																			v-html="newGenConfigurationShortInfo" />
																	</b-col>
																	<template v-if="showDatepicker">
																		<b-col
																			sm="12">
																			<div
																				class="form-group fadeIn animated  mt-2">
																				<label
																					for="nextAutoshipDate"
																					class="label"><b>{{ translate('change_autoship_date') }}</b></label>
																				<b-row
																					no-gutters
																					class="align-items-start">
																					<b-col
																						lg="3"
																						md="3">
																						<div>
																							<v-date-picker
																								id="nextAutoshipDate"
																								v-model="nextAutoshipDate"
																								:input-props="{ class: 'form-control' }"
																								:formats="calendarConfig"
																								name="nextAutoshipDate"
																								show-caps
																								@input="setAutoshipDateClearError('next_autoship_date')" />
																							<template v-if="setAutoshipDateHasError('next_autoship_date')">
																								<ul class="pl-3 mb-0">
																									<li
																										v-for="error in setAutoshipDateErrors['next_autoship_date']"
																										:key="error"
																										class="custom-invalid-feedback animated fadeIn"
																										v-text="error" />
																								</ul>
																							</template>
																						</div>
																					</b-col>
																					<b-col
																						sm="12"
																						md="6"
																						class="d-flex mt-2 mt-lg-0 mt-md-0 justify-content-end justify-content-md-start">
																						<b-button
																							:disabled="setAutoshipDateLoading"
																							class="ml-0 ml-md-2"
																							variant="secondary"
																							@click="toggleDatePicker">
																							{{ translate('cancel') }}
																						</b-button>
																						<b-button
																							class="ml-2 d-flex justify-content-center align-items-center"
																							:disabled="setAutoshipDateLoading"
																							variant="primary"
																							@click="saveDate">
																							<template v-if="!setAutoshipDateLoading">
																								{{ translate('save') }}
																							</template>
																							<template v-else>
																								<i class="fa fa-fw fa-spinner fa-pulse " /> <span class="ml-2">{{ translate('processing') }}</span>
																							</template>
																						</b-button>
																					</b-col>
																				</b-row>
																			</div>
																		</b-col>
																	</template>
																	<template v-if="showNewGenData">
																		<b-col
																			sm="12">
																			<div
																				class="form-group fadeIn animated  mt-2">
																				<label
																					for="nextAutoshipDate"
																					class="label"><b>{{ translate('autoship_periodicity') }}</b></label>
																				<b-row
																					no-gutters
																					class="align-items-start">
																					<b-col
																						lg="6"
																						md="6">
																						<div>
																							<select
																								id="autoship_periodicity"
																								v-model="autoshipPeriodicity"
																								name="autoship_periodicity"
																								class="form-control">
																								<option
																									v-for="configuration in availablePeriodicity()"
																									:key="configuration.code_name"
																									:value="configuration.value">
																									{{ translate(configuration.code_name) }}
																								</option>
																							</select>
																							<template v-if="setAutoshipDateHasError('autoship_periodicity')">
																								<ul class="pl-3 mb-0">
																									<li
																										v-for="error in setAutoshipDateErrors['autoship_periodicity']"
																										:key="error"
																										class="custom-invalid-feedback animated fadeIn"
																										v-text="error" />
																								</ul>
																							</template>
																						</div>
																					</b-col>
																					<b-col
																						sm="12"
																						md="6"
																						class="d-flex mt-2 mt-lg-0 mt-md-0 justify-content-end justify-content-md-start">
																						<b-button
																							:disabled="setAutoshipDateLoading"
																							class="ml-0 ml-md-2"
																							variant="secondary"
																							@click="toggleNewGenData">
																							{{ translate('cancel') }}
																						</b-button>
																						<b-button
																							class="ml-2 d-flex justify-content-center align-items-center"
																							:disabled="setAutoshipDateLoading"
																							variant="primary"
																							@click="saveNewConfiguration">
																							<template v-if="!setAutoshipDateLoading">
																								{{ translate('save') }}
																							</template>
																							<template v-else>
																								<i class="fa fa-fw fa-spinner fa-pulse " /> <span class="ml-2">{{ translate('processing') }}</span>
																							</template>
																						</b-button>
																					</b-col>
																				</b-row>
																				<b-row
																					v-if="isNewGen && showNewGenData"
																					class="mt-3">
																					<b-col>
																						<span
																							v-html="newGenConfigurationInfo" />
																					</b-col>
																				</b-row>
																			</div>
																		</b-col>
																	</template>
																</b-row>
															</b-alert>
														</b-col>
													</b-row>
													<b-row v-if="showNewGenData">
														<b-col class="mt-2">
															<b-alert
																v-if="showPeriodicityWarning && distributor === $user.details().type"
																show
																variant="warning"
																v-html="translate('autoship_date_alert', {'next_purchase_date': newAutoshipDateFormat})" />
															<b-alert
																v-if="customerRoles.includes($user.details().type)"
																show
																variant="warning"
																v-html="translate('customer_autoship_date_alert', {'next_purchase_date': autoshipDateFormat})" />
														</b-col>
													</b-row>
												</div>
											</b-col>
											<b-col class="bg-gray-100 p-2 mx-3 my-2 d-flex flex-column justify-content-center text-center text-light">
												<div class="d-flex justify-content-center">
													<div
														class="bg-primary mx-1 p-1"
														style="border-radius:10px; width:60px; height: 55px;">
														<h3> {{ days }} </h3>
														<h6
															class="text-uppercase"
															style="margin-top: -7px; font-size:smaller">
															{{ translate('countdown_days') }}
														</h6>
													</div>
													<div
														class="bg-primary mx-1 p-1"
														style="border-radius:10px; width:60px; height: 55px;">
														<h3> {{ hours }} </h3>
														<h6
															class="text-uppercase"
															style="margin-top: -7px; font-size:smaller">
															{{ translate('countdown_hours') }}
														</h6>
													</div>
													<div
														class="bg-primary mx-1 p-1"
														style="border-radius:10px; width:60px; height: 55px;">
														<h3> {{ minutes }} </h3>
														<h6
															class="text-uppercase"
															style="margin-top: -7px; font-size:smaller">
															{{ translate('countdown_minutes') }}
														</h6>
													</div>
												</div>
												<div class="text-black-50">
													{{ translate('autoship_countdown_description') }}
												</div>
											</b-col>
											<b-col cols="12">
												<!-- Product loyalty widget -->
												<div
													v-if="!loadingAutoshipConfiguration"
													class="w-100">
													<free-products-promotions
														v-if="!freeProductsPromotionsLoading"
														:promotions="freeProductsPromotions" />
													<is-loading
														v-else
														:loading-label="translate('loading')"
														:no-data-label="translate('data_not_found')"
														:loading="freeProductsPromotionsLoading"
														class="mx-auto" />
												</div>
											</b-col>
										</div>
									</template>
									<is-loading
										:loading="loadingAutoshipConfiguration"
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										size="bg" />
								</b-card>
							</b-col>
						</b-row>
						<!-- Tabs -->
						<b-row class="mt-3">
							<b-col class="col-12">
								<b-tabs
									v-model="tabIndex"
									:content-class="tabIndex == 0 ? 'border-0 border-top' : ''"
									class="mb-3">
									<b-tab
										v-for="(tabInfo, index) in tabs"
										:key="index">
										<template slot="title">
											<div
												:class="tabIndex === index ? 'nav-link active' : 'text-gray-dark'"
												class="list-group-item btn text-left">
												<span
													v-if="tabInfo.icon !== null"
													:class="tabInfo.icon" />
												{{ translate(tabInfo.translate_key) }}
											</div>
										</template>
									</b-tab>
									<template>
										<product-configuration
											v-if="tabIndex == 0"
											:autoship-products-loading="autoshipProductsLoading"
											:autoship-information-has-data="autoshipInformationHasData"
											:autoship-totals-loading="autoshipTotalsLoading"
											:autoship-information-loading="autoshipInformationLoading"
											:autoship-products-data="autoshipProductsData"
											:autoship-totals-data="autoshipTotalsData"
											:autoship-not-available-in-cart="autoshipNotAvailableInCart"
											:autoship-loading="autoshipLoading"
											:products-data="productsData"
											:qtys="qtys"
											:free-shipping-info="freeShippingInfo"
											@qtyChange="handleQuantityChange" />
										<advanced-settings
											v-if="tabIndex == 1"
											:enable-current-products="enableCurrentProducts"
											:show-force-autoship="showForceAutoship"
											:force-autoship-checked="forceAutoshipChecked"
											:use-wallet-funds="useWalletFundsChecked"
											@forceAutoship="forceAutoship"
											@useWalletFunds="useWalletFunds" />
									</template>
								</b-tabs>
							</b-col>
						</b-row>
					</b-collapse>
				</b-card>
			</template>
		</div>
		<b-modal
			ref="updatePeriodicityModal"
			:no-close-on-backdrop="changePeriodicityRequired"
			:no-close-on-esc="changePeriodicityRequired"
			:hide-header-close="changePeriodicityRequired"
			centered
			:title="translate(changePeriodicityRequired ? 'change_autoship_periodicity' : 'autoship_periodicity')">
			<p>{{ translate('current_autoship_date') }}: <b>{{ autoshipDateFormat }}</b></p>
			<p
				v-if="changePeriodicityRequired"
				v-html="translate('periodicity_change_required')" />
			<p
				v-if="!changePeriodicityRequired"
				v-html="translate('periodicity_change_not_required')" />
			<p>{{ translate('current_periodicity') }}: <b>{{ translate(initialPeriodicity || 'max') }}</b></p>
			<template>
				<div
					class="form-group fadeIn animated mt-2 mb-0">
					<label
						for="nextAutoshipDate"
						class="label"><b>{{ translate('autoship_periodicity') }}</b></label>
					<b-row
						no-gutters
						class="align-items-start">
						<b-col>
							<div>
								<select
									id="autoship_periodicity"
									v-model="autoshipPeriodicity"
									name="autoship_periodicity"
									class="form-control">
									<option
										v-for="configuration in availablePeriodicity()"
										:key="configuration.code_name"
										:value="configuration.value">
										{{ translate(configuration.code_name) }}
									</option>
								</select>
								<template v-if="setAutoshipDateHasError('autoship_periodicity')">
									<ul class="pl-3 mb-0">
										<li
											v-for="error in setAutoshipDateErrors['autoship_periodicity']"
											:key="error"
											class="custom-invalid-feedback animated fadeIn"
											v-text="error" />
									</ul>
								</template>
							</div>
						</b-col>
					</b-row>
					<b-row
						v-if="isNewGen"
						class="mt-3">
						<b-col>
							<span
								v-html="newGenConfigurationInfo" />
						</b-col>
					</b-row>
					<b-row>
						<b-col class="mt-2">
							<b-alert
								show
								class="m-0"
								variant="warning"
								v-html="showPeriodicityWarning ?
									translate('autoship_date_alert', {'next_purchase_date': newAutoshipDateFormat}) :
									translate('new_autoship_date_preview', {'autoship_date': newAutoshipDateFormat})" />
						</b-col>
					</b-row>
				</div>
			</template>
			<template #modal-footer>
				<b-button
					v-if="!changePeriodicityRequired"
					class="ml-0 ml-md-2"
					variant="secondary"
					@click="$refs.updatePeriodicityModal.hide()">
					{{ translate('do_not_change_autoship_date') }}
				</b-button>
				<b-button
					class="ml-2 d-flex justify-content-center align-items-center"
					:disabled="setAutoshipDateLoading"
					variant="primary"
					@click="saveNewConfiguration">
					<template v-if="!setAutoshipDateLoading">
						{{ translate('change_autoship_date') }}
					</template>
					<template v-else>
						<i class="fa fa-fw fa-spinner fa-pulse " /> <span class="ml-2">{{ translate('processing') }}</span>
					</template>
				</b-button>
			</template>
		</b-modal>
	</div>
</template>
<script>

import {
	distributor,
	customerRoles,
	customer,
} from '@/settings/Roles';
import Countdown from '@/mixins/Countdown';
import WindowSizes from '@/mixins/WindowSizes';
import Switch from '@/components/Switch';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import {
	AutoshipInformation as Messages, Countries, Products as ProductsMessages, Validations, Profile, Purchase, AutoshipPeriodicity, Dashboard,
} from '@/translations';
// import { SHOW_SIGNATURES_BY_COUNTRY as showSignature } from '@/settings/Shipping';
import AutoshipInformation from '@/util/AutoshipInformation';
import User from '@/util/User';
import {
	MDY_FORMAT,
	YMD_FORMAT,
	YMDHMS_FORMAT,
	V_CALENDAR_CONFIG as calendarConfig,
} from '@/settings/Dates';
import { MAIN_PRODUCT_SKU, POSITIVE_COLOR, POSITIVE_VALUES } from '@/settings/Cart';
import { AVAILABLECC as availableCC } from '@/settings/CreditCard';
import { SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import {
	OLD_GEN_COUNTRIES,
	NEW_GEN_CONFIGURATIONS,
	NO_AUTOSHIP_COUNTRIES,
} from '@/settings/Autoship';
import ProductConfiguration from './ProductConfiguration';
import AdvancedSettings from './AdvancedSettings';
// import AutoshipProduct from './components/AutoshipProduct.vue';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import EventBus from '@/util/eventBus';
import Promotions from '@/util/Promotions';
import FreeProductsPromotions from '@/views/Dashboard/components/FreeProductsPromotions';

export default {
	name: 'AutoshipInformation',
	messages: [Messages, Countries, Dashboard, ProductsMessages, Validations, Profile, Purchase, AutoshipPeriodicity],
	components: {
		FreeProductsPromotions,
		// AutoshipProduct,
		ProductConfiguration,
		AdvancedSettings,
		'switch-toggle': Switch,
	},
	mixins: [ConfigurableProducts, EarlyLaunch, Countdown, WindowSizes],
	data() {
		return {
			enableCurrentProducts: false,
			enableAutoshipChecked: false,
			forceAutoshipChecked: false,
			useWalletFundsChecked: false,
			// signatureChecked: false,
			// showSignature,
			autoshipInformation: new AutoshipInformation(),
			autoshipTotals: new AutoshipInformation(),
			autoshipProducts: new AutoshipInformation(),
			autoshipAddProduct: new AutoshipInformation(),
			updateAutoship: new AutoshipInformation(),
			setAutoshipDate: new AutoshipInformation(),
			user: new User(),
			dateFormat: MDY_FORMAT,
			oldGenCountries: OLD_GEN_COUNTRIES,
			qtys: {},
			productsData: [],
			alert: new this.$Alert(),
			distributor,
			customerRoles,
			positiveColor: POSITIVE_COLOR,
			positiveValues: POSITIVE_VALUES,
			mainProduct: MAIN_PRODUCT_SKU,
			autoshipConfigurations: NEW_GEN_CONFIGURATIONS,
			showDatepicker: false,
			calendarConfig,
			nextAutoshipDate: null,
			showNewGenData: false,
			autoshipPeriodicity: null,
			initialPeriodicity: null,
			nextPurchaseDate: null,
			showPeriodicityWarning: false,
			newAutoshipDate: null,
			newAutoshipDateFormat: '',
			loadingAutoshipConfiguration: false,
			autoshipDate: null,
			autoshipDateFormat: '',
			periodicityDates: {},
			noAutoshipCountries: NO_AUTOSHIP_COUNTRIES,
			tabIndex: 0,
			changePeriodicityRequired: true,
			promotionsInfo: new Promotions(),
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (Number(vm.$cookie.get('is_jns'))) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
	computed: {
		cardLogo() {
			if (this.autoshipInformationData.attributes.credit_card.card_type.length > 0 && availableCC.includes(this.autoshipInformationData.attributes.credit_card.card_type)) {
				return this.autoshipInformationData.attributes.credit_card.card_type;
			}
			return 'cc';
		},
		autoshipProductsLoading() {
			return !!this.autoshipProducts.data.loading;
		},
		autoshipProductsData() {
			try {
				return this.autoshipProducts.data.response.data.data
					.filter((product) => !!product.attributes.available_for_autoship)
					.sort((a, b) => this.productOrderComparison(a, b));
			} catch (error) {
				return [];
			}
		},
		flattenedAutoshipProductSkus() {
			return this.autoshipProductsData.reduce((accumulator, product) => {
				if (product.attributes.has_configurations) {
					const productConfigs = Object.entries(this.getProductConfigs(product));
					return [...accumulator, ...productConfigs.map(([config]) => this.getConfiguredProductSku(product, config))];
				}

				return [...accumulator, product.attributes.sku];
			}, []);
		},
		autoshipProductsHasData() {
			const response = this.autoshipProductsData.length;
			return !!response;
		},
		autoshipInformationLoading() {
			return !!this.autoshipInformation.data.loading;
		},
		autoshipTotalsLoading() {
			return !!this.autoshipTotals.data.loading;
		},
		autoshipLoading() {
			return !!this.autoshipAddProduct.data.loading;
		},
		autoshipTotalsData() {
			try {
				const { data } = this.autoshipTotals.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		autoshipNotAvailableInCart() {
			try {
				const { meta } = this.autoshipTotals.data.response.data;
				return meta.not_available;
			} catch (error) {
				return [];
			}
		},
		smallTextTotals() {
			try {
				return this.autoshipTotalsData.attributes.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		freeShippingInfo() {
			try {
				return this.autoshipTotalsData.attributes.free_shipping_info;
			} catch (error) {
				return null;
			}
		},
		autoshipInformationData() {
			try {
				const { data } = this.autoshipInformation.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		backupCreditCards() {
			try {
				return this.autoshipInformationData.attributes.backup_cards;
			} catch (error) {
				return 0;
			}
		},
		autoshipInformationHasData() {
			const { attributes } = this.autoshipInformationData;
			if (attributes === undefined) return false;

			const response = Object.keys(attributes).length;
			return !!response;
		},
		autoshipInformationQty() {
			try {
				const { limit } = this.autoshipInformation.data.response.data.meta;
				return limit;
			} catch (error) {
				return [];
			}
		},
		equivalencyOfBoxes() {
			try {
				const { product_categories: categories, equivalent_to_a_box: equivalency, weeks_per_box: weeksPerBox } = this.autoshipInformation.data.response.data.meta;
				return { categories, equivalency, weeksPerBox };
			} catch (error) {
				return { categories: {}, equivalency: {}, weeksPerBox: 0 };
			}
		},
		hasAutoshipDate() {
			try {
				return !!this.autoshipInformationData.attributes.next_autoship_date;
			} catch (error) {
				return false;
			}
		},
		isPastDate() {
			try {
				return this.autoshipInformationData.attributes.is_past_date;
			} catch (error) {
				return false;
			}
		},
		isTodayDate() {
			try {
				return this.$moment(this.autoshipInformationData.attributes.next_autoship_date.date).format(this.dateFormat) === this.$moment().tz(this.autoshipInformationData.attributes.next_autoship_date.timezone).format(this.dateFormat);
			} catch (error) {
				return false;
			}
		},
		thereIsExchange() {
			const theresExchange = this.autoshipProductsData.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		setInitialProducts() {
			const initialProducts = {};
			this.flattenedAutoshipProductSkus.forEach((sku) => {
				if (this.qtys[sku] > 0) {
					initialProducts[sku] = this.qtys[sku];
				}
			});
			return initialProducts;
		},
		hasCreditCard() {
			return !Array.isArray(this.autoshipInformationData.attributes.credit_card.address) && Object.keys(this.autoshipInformationData.attributes.credit_card).length > 0;
		},
		hasShippingAddress() {
			return !Array.isArray(this.autoshipInformationData.attributes.shipping_address);
		},
		isActive() {
			try {
				const { user_status: userStatus } = this.autoshipInformation.data.response.data.meta;
				return userStatus === 'active';
			} catch (error) {
				return [];
			}
		},
		isNewGen() {
			try {
				return !this.oldGenCountries.includes(this.$user.details().country.iso_code_2);
			} catch (error) {
				return true;
			}
		},
		showForceAutoship() {
			return this.distributor.includes(this.$user.details().type) && !this.isNewGen;
		},
		setAutoshipDateLoading() {
			return !!this.setAutoshipDate.data.loading;
		},
		newGenConfigurationInfo() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			if (customerRoles.includes(this.$user.details().type)) {
				return this.translate(`customer_${selectedPeriodicity.code_name}_description`);
			}
			return this.translate(`${selectedPeriodicity.code_name}_description`, { autoship_date: this.newAutoshipDateFormat });
		},
		newGenConfigurationShortInfo() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			if (customerRoles.includes(this.$user.details().type)) {
				return this.translate(`customer_${selectedPeriodicity.code_name}_description`);
			}
			return this.translate(`${selectedPeriodicity.code_name}_description_short`);
		},
		userAutoshipPeriodicity() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			return this.translate(selectedPeriodicity.code_name);
		},
		setAutoshipDateErrors: {
			get() {
				try {
					return this.setAutoshipDate.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.setAutoshipDate.errors.errors = newErrors;
			},
		},
		tabs() {
			const initTabs = [
				{
					name: 'ProductConfiguration',
					icon: null,
					translate_key: 'product_configuration',
				},
			];

			if (customer !== this.$user.details().type || this.showForceAutoship) {
				initTabs.push({
					name: 'AdvancedSettings',
					icon: 'fa fa-cogs',
					translate_key: 'advanced_settings',
				});
			}
			return initTabs;
		},
		freeProductsPromotions() {
			try {
				const { response } = this.promotionsInfo.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		freeProductsPromotionsLoading() {
			try {
				return !!this.promotionsInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	watch: {
		autoshipPeriodicity() {
			this.nextDatePreview();
		},
		autoshipDate() {
			this.updateAutoshipDateFormat();
		},
		language() {
			this.updateAutoshipDateFormat();
			this.nextDatePreview();
		},
	},
	mounted() {
		if (this.$user.auth() && !this.earlyLaunch) {
			this.getData();
			const userId = this.$user.details().id;
			this.promotionsInfo.getPromotionsAlert(userId, { autoship: 1 });
		}
	},
	created() {
		if (typeof this.$user.details().package === 'string') {
			this.currentPackage = this.$user.details().package;
		}
	},
	methods: {
		availablePeriodicity() {
			if (customerRoles.includes(this.$user.details().type)) {
				return this.autoshipConfigurations.filter((item) => item.code_name !== 'max');
			}
			const boxes = this.getNumberOfBoxes(this.qtys);
			const { weeksPerBox } = this.equivalencyOfBoxes;
			return this.autoshipConfigurations
				.filter((item) => (item.weeks ?? 0) <= boxes * weeksPerBox);
		},
		async clearProducts() {
			this.productsData = [];
			if (this.enableAutoshipChecked) {
				await this.autoshipTotals.getTotals(this.$user.details().id);
			}

			this.flattenedAutoshipProductSkus.forEach((sku) => {
				this.qtys[sku] = sku === this.mainProduct ? this.autoshipInformationQty.min : 0;
			});
		},
		clearAutoshipConfig() {
			// if (this.signatureChecked === true) this.signatureAutoship(false);
			if (this.forceAutoshipChecked === true) this.forceAutoship(false);
			if (this.useWalletFundsChecked === true) this.useWalletFunds(false);
		},
		productOrderComparison(a, b) {
			const aIndex = sortList.indexOf(a.attributes.sku);
			const bIndex = sortList.indexOf(b.attributes.sku);

			if (aIndex === -1) return 1;
			if (bIndex === -1) return -1;

			return aIndex - bIndex;
		},
		getPeriodicityData(periodicityValue) {
			try {
				return this.autoshipConfigurations.find((element) => element.value === periodicityValue);
			} catch (e) {
				return [];
			}
		},
		async getData() {
			this.productsData = [];
			this.autoshipProducts.data.loading = true;
			this.autoshipTotals.data.loading = true;
			this.autoshipInformation.data.loading = true;

			await this.autoshipTotals.getTotals(this.$user.details().id);
			this.autoshipTotals.data.loading = false;

			await this.loadProductList();

			const cart = await this.autoshipInformation.getAutoship(this.$user.details().id);
			this.enableAutoshipChecked = !!cart.attributes.enable_autoship;
			this.enableCurrentProducts = this.enableAutoshipChecked;
			this.forceAutoshipChecked = !!cart.attributes.force_autoship;
			this.useWalletFundsChecked = !!cart.attributes.use_wallet_funds;
			this.initialPeriodicity = cart.attributes.weeks_periodicity;
			this.autoshipPeriodicity = cart.attributes.weeks_periodicity;
			this.nextPurchaseDate = cart.attributes.next_purchase_date;
			this.newAutoshipDate = cart.attributes.next_autoship_date.date;
			this.newAutoshipDateFormat = this.$moment(this.newAutoshipDate).format(this.dateFormat);
			this.autoshipDate = cart.attributes.next_autoship_date.date;
			this.periodicityDates = cart.attributes.configuration_dates;
			// this.signatureChecked = !!cart.attributes.is_signature_required;

			let totalQty = 0;
			Object.keys(cart.attributes.products).forEach((sku) => {
				totalQty += cart.attributes.products[sku];
			});
			const remainingQty = this.autoshipInformationQty.min - totalQty;
			this.countdownToProcessAutoship();

			this.flattenedAutoshipProductSkus.forEach((sku) => {
				const isProductFillable = totalQty < this.autoshipInformationQty.min && sku === this.mainProduct;
				const productQty = cart.attributes.products[sku] || 0;

				this.qtys[sku] = isProductFillable ? productQty + remainingQty : productQty;
			});

			this.autoshipInformation.data.loading = false;
		},
		async loadProductList() {
			this.productsData = [];
			const products = (await this.autoshipProducts.getProducts(this.$user.details().id))
				.filter((product) => !!product.attributes.available_for_autoship)
				.sort((a, b) => this.productOrderComparison(a, b));
			this.autoshipProducts.data.loading = false;

			products.forEach((item) => {
				let hasDiscount = false;
				let discountPrice = 0;
				let exchangePrice = item.attributes.exchange_price;

				if (this.hasDiscount(item, this.currentPackage)) {
					discountPrice = this.discount(item, this.currentPackage).value;
					hasDiscount = true;
					exchangePrice = this.discount(item, this.currentPackage).exchange_price;
				}

				this.productsData.push({
					...this.transformProduct(item),
					hasDiscount,
					discountPrice,
					exchangePrice,
				});
			});
		},
		handleAutoshipToggle() {
			if (!this.enableAutoshipChecked) {
				const autoshipMessage = 'disable_autoship_message';
				const trans = {
					title: this.translate('disable_autoship'),
					text: this.translate(autoshipMessage),
				};
				const options = {
					buttonText: this.translate('ok'),
					cancelButtonText: this.translate('cancel'),
				};
				this.alert.confirmation(trans.title, trans.text, options, false).then(() => {
					this.changeAutoshipStatus();
				}).catch(() => {
					this.enableAutoshipChecked = !this.enableAutoshipChecked;
					this.enableCurrentProducts = true;
				});
			} else {
				this.changeAutoshipStatus();
			}
		},
		changeAutoshipStatus() {
			this.enableCurrentProducts = this.enableAutoshipChecked;
			this.$nextTick(() => {
				this.updateAutoship.updateAutoship(this.$user.details().id, { enable_autoship: this.enableAutoshipChecked }).then(() => {
					if (this.enableAutoshipChecked) {
						this.handleQuantityChange(this.setInitialProducts);
						this.loadProductList();
					}
					if (!this.enableAutoshipChecked) {
						this.clearProducts();
						this.clearAutoshipConfig();
						EventBus.$emit('updateAccountSteps');
						this.alert.toast('success', this.translate('enable_autoship_success'));
					}
				}).catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
			});
		},
		forceAutoship(status = true) {
			this.forceAutoshipChecked = !this.forceAutoshipChecked;
			this.$nextTick(() => {
				this.updateAutoship.updateAutoship(this.$user.details().id, { force_autoship: this.forceAutoshipChecked }).then(() => {
					if (status !== false) this.alert.toast('success', this.translate('force_autoship_success'));
				}).catch(() => {
					if (status !== false) this.alert.toast('error', this.translate('default_error_message'));
				});
			});
		},
		useWalletFunds(status = true) {
			this.useWalletFundsChecked = !this.useWalletFundsChecked;
			this.$nextTick(() => {
				this.updateAutoship.updateAutoship(this.$user.details().id, { use_wallet_funds: this.useWalletFundsChecked }).then(() => {
					if (status !== false) this.alert.toast('success', this.translate('wallet_funds_success'));
				}).catch(() => {
					if (status !== false) this.alert.toast('error', this.translate('default_error_message'));
				});
			});
		},
		// signatureAutoship(status = true) {
		// 	this.signatureChecked = !this.signatureChecked;
		// 	this.$nextTick(() => {
		// 		this.updateAutoship.updateAutoship(this.$user.details().id, { is_signature_required: this.signatureChecked }).then(() => {
		// 			if (status !== false) this.alert.toast('success', this.translate('signature_success'));
		// 		}).catch(() => {
		// 			if (status !== false) this.alert.toast('error', this.translate('default_error_message'));
		// 		});
		// 	});
		// },
		getNumberOfBoxes(products) {
			const { categories, equivalency } = this.equivalencyOfBoxes;
			const qtyByCategory = {};
			Object.entries(categories).forEach(([category, items]) => {
				let sum = 0;
				Object.entries(products).forEach(([sku, qty]) => {
					sum += items.includes(sku) ? qty : 0;
				});
				qtyByCategory[category] = sum;
			});
			let boxes = 0;
			Object.entries(qtyByCategory).forEach(([category, qty]) => {
				let mappedQty = qty;
				if (Object.hasOwn(equivalency, category)) {
					const equivalent = equivalency[category];
					mappedQty = Math.floor(qty / equivalent.multiple);
					if (qty >= equivalent.min && qty <= equivalent.multiple) {
						mappedQty = 1;
					}
				}
				boxes += mappedQty;
			});
			return boxes;
		},
		async handleQuantityChange(qtys) {
			if (Object.entries(qtys).some(([, qty]) => qty > 0)) {
				try {
					await this.autoshipAddProduct.addProduct(this.$user.details().id, { products: qtys });
					try {
						await this.autoshipTotals.getTotals(this.$user.details().id);

						if (this.$user.details().type === distributor) {
							const availableOptions = this.availablePeriodicity().map((item) => item.code_name);
							if (!availableOptions.includes(this.initialPeriodicity ?? 'max')) {
								this.changePeriodicityRequired = true;
								this.autoshipPeriodicity = null; // To select the maximum option by default in the modal
								this.$refs.updatePeriodicityModal.show();
							} else if (availableOptions.indexOf(this.initialPeriodicity ?? 'max') !== availableOptions.length - 1) {
								this.changePeriodicityRequired = false;
								this.$refs.updatePeriodicityModal.show();
							}
						}

						this.alert.toast('success', this.translate('enable_autoship_success'));
					} catch (error) {
						this.alert.toast('error', this.translate('something_went_wrong'));
					}
				} catch (error) {
					this.alert.toast('error', this.autoshipAddProduct.data.errors.errors.products[0]);
				}
			} else {
				this.enableAutoshipChecked = !this.enableAutoshipChecked;
				return this.handleAutoshipToggle();
			}
			EventBus.$emit('updateAccountSteps');
			return null;
		},
		discount(product, packageCodeName) {
			try {
				if (Object.keys(product.attributes.discount).includes(packageCodeName)) {
					return product.attributes.discount[packageCodeName];
				}
			} catch (error) {
				return {};
			}
			return {};
		},
		hasDiscount(product, packageCodeName) {
			return !!(Object.keys(this.discount(product, packageCodeName)).length);
		},
		setAutoshipDateHasError(field) {
			if (typeof this.setAutoshipDateErrors[field] !== 'undefined') { return true; }
			return false;
		},
		setAutoshipDateClearError(field) {
			if (field) {
				delete this.setAutoshipDateErrors[field];
				this.setAutoshipDateErrors = { ...this.setAutoshipDateErrors };
			}
		},
		toggleDatePicker() {
			this.showDatepicker = !this.showDatepicker;
			this.nextAutoshipDate = new Date(this.$moment(this.autoshipDate).format(YMDHMS_FORMAT));
			this.setAutoshipDateClearError('next_autoship_date');
		},
		toggleNewGenData() {
			this.showNewGenData = !this.showNewGenData;
			this.autoshipPeriodicity = this.initialPeriodicity;
		},
		saveDate() {
			this.setAutoshipDate.setAutoshipDate(this.$user.details().id, { next_autoship_date: this.$moment(this.nextAutoshipDate).format(YMD_FORMAT) }).then(() => {
				this.showDatepicker = !this.showDatepicker;
				if (this.isPastDate || this.isTodayDate) {
					this.getData();
				} else {
					this.autoshipDate = this.$moment(this.nextAutoshipDate).format(YMD_FORMAT);
					this.countdownToProcessAutoship();
				}
			});
		},
		saveNewConfiguration() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			const trans = {
				title: this.translate('change_autoship_configuration'),
				text: this.translate('change_autoship_configuration_text', { autoship_frequency: this.translate(selectedPeriodicity.code_name), autoship_date: this.newAutoshipDateFormat }),
			};
			if (customerRoles.includes(this.$user.details().type) && this.autoshipPeriodicity !== 'monthly') {
				trans.text += this.translate('possible_loyalty_loss');
			}
			const options = {
				confirmButtonText: this.translate('confirm_autoship_configuration'),
				cancelButtonText: this.translate('do_not_change_autoship_date'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				this.loadingAutoshipConfiguration = true;
				this.setAutoshipDate.updateAutoshipPeriodicity(this.$user.details().id, { autoship_periodicity: this.autoshipPeriodicity }).then((response) => {
					this.$refs.updatePeriodicityModal.hide();
					this.showNewGenData = false;
					this.alert.toast('success', response.response.message);

					if (this.isPastDate || this.isTodayDate) {
						this.getData();
					} else {
						this.autoshipDate = this.newAutoshipDate;
						this.initialPeriodicity = this.autoshipPeriodicity;
					}
					this.loadingAutoshipConfiguration = false;
				});
			}).catch(() => {});
		},
		transformProduct(product) {
			const { attributes, ...others } = product;
			return { ...attributes, ...others };
		},
		updateAutoshipDateFormat() {
			try {
				this.autoshipDateFormat = this.$moment(this.autoshipDate).format(this.dateFormat);
			} catch (e) {
				this.autoshipDateFormat = '';
			}
		},
		nextDatePreview() {
			try {
				if (customerRoles.includes(this.$user.details().type)) {
					return;
				}
				const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
				this.newAutoshipDate = this.periodicityDates[selectedPeriodicity.code_name].date.date;
				this.newAutoshipDateFormat = this.$moment(this.newAutoshipDate).format(this.dateFormat);
				this.showPeriodicityWarning = this.periodicityDates[selectedPeriodicity.code_name].show_alert;
			} catch (e) {
				this.newAutoshipDateFormat = '';
				this.showPeriodicityWarning = false;
			}
		},
		countdownToProcessAutoship() {
			if (!this.autoshipDate) {
				return;
			}
			this.countdown(this.autoshipDate);
		},
	},
};
</script>
<style>
	.bottom {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.border-color {
		border-top: 5px solid #3A8EB7
	}
	.border-top {
		border-top: 1px solid gray
	}
	.p-text {
		display:inline;
		color:#8e8e8e;
		font-style: italic;
	}
</style>
<style scoped>
	.card-body {
		padding-bottom: 0px !important;
	}
	.grid-container {
		display: grid;
		grid-template-columns: max-content auto;
		gap: 10px;
	}

	@media (max-width: 915px) {
		.grid-container {
			grid-template-columns: 1fr;
		}
	}
</style>
