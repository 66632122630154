<template>
	<div>
		<template v-if="stackedLayout">
			<div
				class="row pointer"
				@click="$emit('toggleExpanded')">
				<div class="col-12 d-flex">
					<div class="my-auto mx-auto">
						<img
							:src="thumbnail"
							class="img-fluid float-right">
					</div>
				</div>
				<div class="col-12 d-flex p-0">
					<div class="mb-2 mx-auto text-center">
						<span class="font-weight-bolder"><i
							v-if="$route.name === 'AutoshipInformation'"
							:class="`fas ${ isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'}`" /> {{ translate(codeName) }}</span><br>
						<div v-if="showBvs">
							{{ translate('bvs', { qty: bvs }) }}
						</div>
					</div>
				</div>
				<div class="col-12 d-flex">
					<div class="mx-auto text-right mb-2">
						<span class="font-weight-bolder h5">{{ price }}</span><br>
						<p
							v-if="exchange"
							class="exchange-text small">
							{{ exchangePrice }}*
						</p>
					</div>
				</div>
			</div>
			<b-collapse :visible="isExpanded">
				<div class="row mt-1 mb-2">
					<div class="col-12">
						<div class="row justify-content-center">
							<div class="col-9 text-center px-0 pb-2 mb-2 border-bottom" />
						</div>
						<div class="row">
							<div class="col-12 d-flex justify-content-center">
								<div class="d-block">
									<slot />
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-collapse>
		</template>
		<div
			v-else
			class="row">
			<div class="col-3 d-flex">
				<div class="align-self-start mx-auto mt-1">
					<img
						:src="thumbnail"
						class="img-fluid float-right pointer"
						@click="$emit('toggleExpanded')">
				</div>
			</div>
			<template>
				<div
					class="col-9 pb-2"
					:class="{
						'pt-4': isExpanded
					}">
					<div
						class="row pointer my-row"
						:class="{
							'pb-3': isExpanded,
							'h-100': !isExpanded
						}"
						@click="$emit('toggleExpanded')">
						<div class="col-6 d-flex p-0">
							<div class="my-auto">
								<span class="font-weight-bolder"><i
									v-if="$route.name === 'AutoshipInformation'"
									:class="`fas ${ isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'}`" /> {{ translate(codeName) }}</span><br>
								<div v-if="showBvs">
									{{ translate('bvs', { qty: bvs }) }}
								</div>
							</div>
						</div>
						<div class="col-6 d-flex justify-content-end">
							<div class="mr-3 my-auto text-right">
								<span class="font-weight-bolder h5">{{ price }}</span><br>
								<p
									v-if="exchange"
									class="exchange-text small">
									{{ exchangePrice }}*
								</p>
							</div>
						</div>
					</div>
					<transition name="slide-fade">
						<div
							v-show="isExpanded"
							class="row mb-0">
							<div class="col-12">
								<div class="row">
									<div class="col-11 px-0 mb-3 border-bottom" />
								</div>
								<div class="row">
									<div class="col-12 pl-0">
										<slot />
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
import { PRODUCT_OVERRIDE_PRICE, PRODUCT_VOLUME, PRODUCT_DEFAULT_PRICE } from '@/settings/Products';
import { Products, Purchase } from '@/translations';

export default {
	name: 'ConfigurableProduct',
	messages: [Products, Purchase],
	props: {
		codeName: {
			type: String,
			required: true,
		},
		configName: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			required: true,
		},
		showBvs: {
			type: Boolean,
			default: true,
		},
		initBvs: {
			type: Number,
			default: 0,
		},
		rangeBvs: {
			type: String,
			default: '',
		},
		initPrice: {
			type: String,
			default: '',
		},
		initExchange: {
			type: Boolean,
			default: false,
		},
		initExchangePrice: {
			type: String,
			default: '',
		},
		sku: {
			type: String,
			required: true,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		isExpanded: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		bvs() {
			return this.getDisplayBvs(this.initBvs, this.rangeBvs, this.sku);
		},
		price() {
			return this.getDisplayPrice(this.initPrice, this.sku);
		},
		exchange() {
			return !!PRODUCT_OVERRIDE_PRICE[this.sku]?.exchangePrice || this.initExchange || !!PRODUCT_DEFAULT_PRICE[this.sku]?.exchangePrice;
		},
		exchangePrice() {
			return this.getDisplayPrice(this.initExchangePrice, this.sku, 'exchangePrice');
		},
	},
	methods: {
		getDisplayPrice(price, sku, priceKey = 'price') {
			if (PRODUCT_OVERRIDE_PRICE[this.country]) {
				if (PRODUCT_OVERRIDE_PRICE[this.country][sku]?.[priceKey]) {
					return PRODUCT_OVERRIDE_PRICE[this.country][sku][priceKey];
				}
			}

			if (PRODUCT_OVERRIDE_PRICE[sku]?.[priceKey]) {
				return PRODUCT_OVERRIDE_PRICE[sku][priceKey];
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]?.[priceKey]) {
				return PRODUCT_DEFAULT_PRICE[sku][priceKey];
			}

			return price;
		},
		getDisplayBvs(bvs, rangeBvs, sku) {
			if (PRODUCT_VOLUME[sku]?.volume) {
				return PRODUCT_VOLUME[sku].volume;
			}

			return (rangeBvs !== '') ? rangeBvs : bvs;
		},
	},
};
</script>
<style>
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: opacity 0s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}
</style>
