<template>
	<div>
		<b-row
			v-if="customer !== $user.details().type"
			class="my-3">
			<b-col :class="switchClass">
				<switch-toggle
					:checked="useWalletFunds"
					:disabled="!enableCurrentProducts"
					no-margin
					variant="success"
					pill
					class="ml-3"
					style="vertical-align: top"
					@change="$emit('useWalletFunds')" />
				<b><p
					style="display:inline;"
					class="ml-2 mb-0">{{ translate('use_wallet_funds') }}</p></b>
			</b-col>
			<b-col :class="textClass">
				<div class="px-3">
					<p class="text-left mt-3 p-text">
						{{ translate(`use_wallet_funds_description`) }}
					</p>
				</div>
			</b-col>
		</b-row>
		<b-row
			v-if="showForceAutoship"
			class="my-3">
			<b-col :class="switchClass">
				<switch-toggle
					:checked="forceAutoshipChecked"
					:disabled="!enableCurrentProducts"
					variant="success"
					no-margin
					pill
					class="ml-3"
					style="vertical-align: top"
					@change="$emit('forceAutoship')" />
				<b><p
					style="display:inline;"
					class="mb-0 ml-2">{{ translate('force_autoship') }}</p></b>
			</b-col>
			<b-col :class="textClass">
				<div class="px-3">
					<p class="text-left mt-3 p-text">
						{{ translate(`force_autoship_text_${forceAutoshipChecked}`) }}
					</p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Switch from '@/components/Switch';
import { customer } from '@/settings/Roles';
import {
	AutoshipInformation as Messages,
} from '@/translations';

export default {
	name: 'AdvancedSettings',
	messages: [Messages],
	components: {
		// AutoshipProduct,
		'switch-toggle': Switch,
	},
	mixins: [WindowSizes],
	props: {
		forceAutoshipChecked: {
			type: Boolean,
			default: false,
		},
		enableCurrentProducts: {
			type: Boolean,
			default: false,
		},
		showForceAutoship: {
			type: Boolean,
			default: false,
		},
		useWalletFunds: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			customer,
		};
	},
	computed: {
		switchClass() {
			return {
				'col-3': !['xs', 'sm'].includes(this.windowWidth),
				'col-12': ['xs', 'sm'].includes(this.windowWidth),
			};
		},
		textClass() {
			return {
				'col-9': !['xs', 'sm'].includes(this.windowWidth),
				'col-12 mt-2': ['xs', 'sm'].includes(this.windowWidth),
			};
		},
	},
};
</script>
